import {isFeatureEnabled} from '@github-ui/feature-flags'

import {Grid, Footnotes} from '@primer/react-brand'

import {analyticsEvent} from '../../../../lib/analytics'

export default function FootnotesSection() {
  const isCopilotProPlusEnabled = isFeatureEnabled('site_copilot_pro_plus')

  return (
    <section id="footnotes" className="lp-Section lp-Section--level-1 lp-Section--footnotes" style={{paddingTop: '0'}}>
      <Grid className="lp-Grid--noRowGap">
        <Grid.Column span={12}>
          <Footnotes>
            {isCopilotProPlusEnabled ? (
              <>
                <Footnotes.Item id="footnote-1" href="#footnote-ref-1">
                  Option to purchase additional premium requests not available to users that subscribe or have
                  subscribed to Pro or Pro+ through GitHub Mobile on iOS or Android.
                </Footnotes.Item>

                <Footnotes.Item id="footnote-2" href="#footnote-ref-2">
                  <a
                    className="lp-Link--inline"
                    href="http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://docs.github.com/en/enterprise-cloud@latest/authentication/authenticating-with-saml-single-sign-on/about-authentication-with-saml-single-sign-on"
                    {...analyticsEvent({
                      action: 'saml_sso',
                      tag: 'link',
                      context: 'footnote',
                      location: 'features_table',
                    })}
                  >
                    Authentication with SAML single sign-on (SSO)
                  </a>{' '}
                  available for organizations using GitHub Enterprise Cloud.
                </Footnotes.Item>
              </>
            ) : (
              <Footnotes.Item id="footnote-1" href="#footnote-ref-1-1">
                <a
                  className="lp-Link--inline"
                  href="http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://docs.github.com/en/enterprise-cloud@latest/authentication/authenticating-with-saml-single-sign-on/about-authentication-with-saml-single-sign-on"
                  {...analyticsEvent({
                    action: 'saml_sso',
                    tag: 'link',
                    context: 'footnote',
                    location: 'features_table',
                  })}
                >
                  Authentication with SAML single sign-on (SSO)
                </a>{' '}
                available for organizations using GitHub Enterprise Cloud.
              </Footnotes.Item>
            )}
          </Footnotes>
        </Grid.Column>
      </Grid>
    </section>
  )
}

try{ FootnotesSection.displayName ||= 'FootnotesSection' } catch {}