import {Grid, SectionIntro, Stack, Pillar} from '@primer/react-brand'
import {getAnalyticsEvent} from '@github-ui/swp-core/lib/utils/analytics'

import IntegrationsCliImage from '../_assets/integrations-cli.webp'
import IntegrationsMobileImage from '../_assets/integrations-mobile.webp'

export default function IntegrationsSection() {
  return (
    <section className="lp-Section lp-Section--compact lp-SectionIntro--compact">
      <Grid className="lp-Section-container--centerUntilMedium lp-Grid--noRowGap">
        <Grid.Column span={12}>
          <SectionIntro fullWidth align="center" className="lp-SectionIntro">
            <SectionIntro.Heading size="3" weight="semibold">
              GitHub Copilot works where you work
            </SectionIntro.Heading>
          </SectionIntro>

          <Stack
            direction={{narrow: 'vertical', regular: 'horizontal', wide: 'horizontal'}}
            gap="spacious"
            padding="none"
          >
            <Pillar style={{maxWidth: '100%'}}>
              <Pillar.Image src={IntegrationsCliImage} alt="Screenshot of GitHub Copilot CLI in a terminal." />

              <Pillar.Heading>Command the command line</Pillar.Heading>

              <Pillar.Description>
                Tap into GitHub Copilot in your terminal for instant command-line help.
              </Pillar.Description>

              <Pillar.Link
                href="http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://docs.github.com/copilot/using-github-copilot/using-github-copilot-in-the-command-line"
                {...getAnalyticsEvent({
                  action: 'try_in_cli',
                  tag: 'link',
                  context: 'integrations_pillar',
                  location: 'integrations',
                })}
              >
                Try Copilot in the CLI
              </Pillar.Link>
            </Pillar>

            <Pillar style={{maxWidth: '100%'}}>
              <Pillar.Image
                src={IntegrationsMobileImage}
                alt="Phone screens showing GitHub Copilot in the GitHub Mobile app."
              />

              <Pillar.Heading>Start a chat anywhere</Pillar.Heading>

              <Pillar.Description>Use Copilot Chat on the go with native apps for iOS and Android.</Pillar.Description>

              <Pillar.Link
                href="http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://github.com/mobile"
                {...getAnalyticsEvent({
                  action: 'chat_in_mobile',
                  tag: 'link',
                  context: 'integrations_pillar',
                  location: 'integrations',
                })}
              >
                Chat in GitHub Mobile
              </Pillar.Link>
            </Pillar>
          </Stack>
        </Grid.Column>
      </Grid>
    </section>
  )
}

try{ IntegrationsSection.displayName ||= 'IntegrationsSection' } catch {}